/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useContext, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { useLocation, withRouter } from 'react-router';
import {
    object, string, func, shape, array, bool, number, arrayOf,
} from 'prop-types';
import { getContactId } from '../../../../../../../state/ducks/Member/ducks/Common/Common-Selectors';
import {
    getIsProductFilterZipcodeValid, getWineData, getWineAgeVerified, getSubscriptionFrequencyData, getProductUnAvailable,
} from '../../../../../../../state/ducks/App/App-Selectors';
import { getShopTheSite } from '../../../../../../pages/Account/utils/shopTheSite';

import { useIntersect } from '../../../../../../helpers/page/pageHelpers';
import { setAddtoCartPosition } from '../../../../../../../state/ducks/App/App-Actions';
import AddToCartErorMessage from '../Partials/AddToCartErrorMessage/AddToCartErrorMessage';
import MaxCartErrorMessage from '../../../../../../pages/Checkout/containers/Common/MaxCartErrorMessage/MaxCartErrorMessage';
import { addToCartNewCartServices } from '../../../../../../../state/ducks/AddToCart/AddToCart-Actions';
import { getAddToCartError } from '../../../../../../../state/ducks/AddToCart/AddToCart-Selectors';
import { getPmallSummaryInfo } from '../../../../../../../state/ducks/Common/Common-Selectors';
import AddToCartBuilderObject from '../Partials/AddToCartBuilderObject';
import { CrossMerch } from '../../../../CrossMerchContainer/CrossMerchContainer';
import PersonalizationModal from '../../PersonalizationModal';
import AddonVerification from '../../CommonProductAgeZipVerify/CommonProductAgeZipVerify';
import useIsPlaPage from '../../../../../../helpers/hooks/useIsPlaPage';
import { getFeatureFlag } from '../../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import KlarnaCreditPromotionBadge from '../../../../../Checkout/KlarnaPaymentOnSiteMessage/KlarnaCreditPromotionBadge/KlarnaCreditPromotionBadge';

const styles = ({ palette, typography }) => ({
    buttonWrap: {
        width: '100%',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    addToCartButton: {
        color: palette.white,
        fontFamily: typography.fontFamily,
        boxSizing: 'border-box',
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',
        fontWeight: '700',
        width: '100%',
        height: '50px',
        fontSize: '1em',
        textTransform: 'uppercase',
        borderRadius: '28px',
        lineHeight: '1.46em',
        padding: '7px 24px 5px 24px',
        '&:disabled': {
            backgroundColor: `${palette.colorDisabled || '#ccc'} !important`,
            color: palette.white || '#fff',
        },
    },
    addSubscriptionToCartButton: {
        color: palette.white,
        fontFamily: typography.fontFamily,
        boxSizing: 'border-box',
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',
        fontWeight: '600',
        width: '255px',
        height: '50px',
        fontSize: '15px',
        textTransform: 'uppercase',
        borderRadius: '28px',
        lineHeight: '1.46em',
        '&:disabled': {
            backgroundColor: palette.cms?.wineVerifyDisable,
            color: palette.disableButton,
        },
    },
    cyoAddToCartButton: {
        color: palette.white || '#fff',
        fontFamily: typography.fontFamily,
        boxSizing: 'border-box',
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',
        fontWeight: '700',
        width: '100%',
        height: '40px',
        fontSize: '1em',
        textTransform: 'uppercase',
        borderRadius: '20px',
        lineHeight: '1.46em',
        padding: '5px 24px 5px 24px',
        '&:disabled': {
            backgroundColor: `${palette.colorDisabled || '#ccc'} !important`,
            color: palette.white || '#fff',
        },
    },
    addtoCartStickToHeader: {
        transition: 'all 0.7s cubic-bezier(0.22, 0.61, 0.36, 1) 0s',
        position: 'fixed',
        top: '2px',
        width: 'calc((100% - 24px) - 80px)',
        left: 'calc((24px + 80px)/2)',
        zIndex: 4,
    },
    addToCartBg: {
        backgroundColor: `${palette.primaryButton}`,
        '&:hover': {
            backgroundColor: palette.primaryButton,
        },
    },
    addToCartButtonFullWidth: {
        color: palette.white,
        backgroundColor: `${palette.primaryButton}`,
        fontFamily: typography.fontFamily,
        boxSizing: 'border-box',
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',
        fontWeight: '700',
        height: '50px',
        fontSize: '1em',
        textTransform: 'uppercase',
        borderRadius: '28px',
        lineHeight: '1.46em',
        padding: '7px 24px 5px 24px',
        '&:disabled': {
            backgroundColor: `${palette.cms?.wineVerifyDisable}`,
            color: palette.primary?.contrastText,
        },
    },
    sorryMessage: {
        fontSize: '15px',
        color: palette.cms?.errorZipVerifyPDP,
        fontFamily: typography?.fontFamily,
        textAlign: 'center',
        margin: '12px 0px 0px 0px',
        padding: '5px 10px',
    },
    outOfStockMessage: {
        fontSize: '15px',
        color: palette.cms?.errorZipVerifyPDP,
        fontFamily: typography?.fontFamily,
        textAlign: 'center',
        margin: 0,
    },
    addToCartBtn: {
        '& $addToCartButton , $addToCartButtonFullWidth': {
            borderRadius: '0px',
            minWidth: '100%',
            '&:hover': {
                backgroundColor: palette.primaryButton,
            },
        },
    },
    disabledBtn: {
        textAlign: 'center',
        borderRadius: '0px',
        width: '100%',
        padding: '14px 24px 14px 24px',
        fontFamily: typography.fontFamily,
        fontSize: '1em',
        lineHeight: '1.46em',
        fontWeight: 'bold',
        marginTop: '15px',
    },
    fullDetail: {
        color: palette?.ctaButton || '#2F2F2F',
        fontSize: '16px',
        lineHeight: '24px',
    },
    plaAddtoCart: {
        borderRadius: 'inherit',
        marginTop: '18px',
    },
    inlineAddon: {
        borderRadius: 'inherit',
    },
    klarnaWidget: {
        display: 'flex',
        justifyContent: 'center',
        '& >div': {
            paddingTop: '2px !important', // overwrite klarna widget css
        },
    },
    addToCart: {
        marginBottom: '0px !important',
    },

    shipmentMessage: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '18px',
        textTransform: 'capitalize',
        color: palette.upsellsTextColor || '#2F2F2F',
        marginBottom: '1px',
        textDecoration: 'none',
        textAlign: 'center',
        marginTop: '6px',

    },
    shipmentMessageFuture: {
        fontWeight: '700',
        color: palette.upsellsFutureAvailabilityColor || '#A2011A',
    },
    availabilityLeftAlign: {
        justifyContent: 'center',
        '&$addToCartBtn, $addToCartButton, $addToCartButtonFullWidth': {
            margin: '15px 0px 16px !important',
        },
    },
    byoAddToCart: {
        borderRadius: '2px',
    },
    newPdpAddToCart: {
        '& button': {
            borderRadius: '0px',
            height: '56px',
            padding: '0px',
            fontSize: '18px',
            lineHeight: '26px',
            letterSpacing: '0.5px',
            textTransform: 'capitalize',
        },
    },
    shipmentWrapper: {
        fontSize: '14px',
        textAlign: 'left',
        marginTop: '0px',
        lineHeight: '21px',
    },
});

const MobileFoodAddToCart = ({
    classes,
    history,
    selectedItemId,
    brandId,
    actionAddToCart,
    partNumber,
    categoryId,
    subscription,
    subscriptionDetails,
    productType,
    totalProductSelected,
    maxComponents,
    fullWidth,
    cyoSelectedComponents,
    disableAddToCart,
    personalizedData,
    isPersonalizable,
    isAddToCartEnabled,
    actionSetAddToCartPosition,
    wineValidation,
    quantity,
    categoryPath,
    categoryName,
    isBandItemSelected,
    handleMobileAgeVerification,
    isMobile,
    hasWine,
    wineAgeVerified,
    addons,
    isPassportBundleProduct,
    contactId,
    movieSelected,
    productDemandType,
    deliveryMethod,
    addToCartError,
    isTryMeProduct,
    deliveryIndicatorPDP,
    pmallSummaryInfo,
    handleMiniCartModalClick,
    enableMinicart,
    actionType,
    subscriptionValues,
    selectedSku,
    open,
    setOpen,
    closePersonalizationModal,
    setPersonalizationState,
    personalizationData,
    setPersonalization,
    handlePersonalizationTextFieldChange,
    zipValid,
    selectAddons,
    product,
    pdpInlineAddon,
    quickView,
    closeAddonModal,
    isBYOProduct,
    atcCallBack,
    content,
    isPdpRedesignEnabled,
}) => {
    const [addToCartPosition, setAddToCartPosition] = React.useState();
    const [openModal, setOpenModal] = React.useState(false);
    const { featureFlags = {} } = useContext(CrossMerch);
    const isMonthOnlyPayFull = selectedSku?.subscriptions?.type === 'MonthOnlyPayFull' && featureFlags['disable-month-only-pay-full-pdp-subscription-banner'];
    const isSubscriptionChecked = (subscription?.subscriptionCheckbox && !isMonthOnlyPayFull) || false;
    const productUnAvailable = useSelector(getProductUnAvailable);
    const multipleLinesData = selectedSku.personalization?.lines?.length > 1 && selectedSku.personalization?.indicator ? personalizationData : personalizedData;
    const enableMiniCartView = featureFlags['is-mini-cart-v2-enabled'] ? true : enableMinicart;
    const isAvailabilityAtcLeftAlign = featureFlags['is-availability-atc-left-aligned'];
    const leftAlignClass = isAvailabilityAtcLeftAlign && actionType?.toLowerCase() !== 'quick view' ? classes.availabilityLeftAlign : '';
    // Quick view doesn't have FF from cross merch
    const brandWineModelOnAction = useSelector(getFeatureFlag('is-inline-wine-verification-model-on-atc-enabled')) || false;
    const wineModelOnAction = featureFlags['is-inline-wine-verification-model-on-atc-enabled'] || brandWineModelOnAction;
    const handleAddToCart = (productId = null, returnObject = false) => {
        const subscriptionData = {
            isSelected: subscription?.subscriptionCheckbox,
            hasFrequencyList: featureFlags['is-subscription-frequency'],
            hasDurationList: featureFlags['is-subscription-duration'],
            duration: subscriptionValues?.duration || subscriptionDetails?.subscriptionDurationKey,
            interval: subscriptionValues?.subscriptionInterval || subscriptionDetails?.subscriptionFrequency,
            type: subscriptionValues?.subscriptionType || subscriptionDetails?.subscriptionType,
        };

        // use same name that we need to pass to the attributeName in cart API call
        const attributes = {
            categoryId,
            categoryPath,
            categoryIdent: categoryName,
            demandTypeCode: productDemandType,
            tryMe: isTryMeProduct,
        };

        const wineVerify = {
            hasWine,
            wineValidation,
        };
        let personalizationLines;
        if (personalizationData.personalization?.lineState) {
            personalizationLines = personalizationData.personalization.lineState?.map((line, i) => ({
                perAttributeId: `${i + 1}`,
                perAttributeValue: personalizationData.personalization.lineState[i].lineText,
            }));
        }

        const personalizationValue = {
            isPersonalizable,
            multipleLinesData,
            personalizedData,
        };

        // GiftList - get Shop The Site content
        const giftList = getShopTheSite(contactId) || {};

        let productLineItemType = productType;
        // The products only needs firstname, lastname and email,
        // without send emails at the end of place order (example: market place vendor)
        if (
            selectedSku?.parentProduct?.categories?.includes('digital_delivery')
        ) {
            productLineItemType = 'DDELVR';
        }

        // The product when is NBCU
        if (
            selectedSku?.parentProduct?.categories?.includes('digital_products')
            || selectedSku?.name?.toLowerCase()?.includes('digital movie gift')
        ) {
            productLineItemType  = 'NBCU';
        }

        const ATC = new AddToCartBuilderObject(
            brandId,
            (productId || selectedItemId),
            quantity,
            cyoSelectedComponents || [],
            addons || [],
            productLineItemType,
            subscriptionData,
            attributes,
            wineVerify,
            personalizationValue,
            pmallSummaryInfo,
            giftList,
            deliveryIndicatorPDP,
            deliveryMethod,
            contactId,
            selectedSku,
        );
        const reqObj = ATC.getPayload();

        /**
             * Handle req object personlization fields
             * for internal products
             */
        if (personalizationLines) {
            reqObj.personalization = {
                personalizationType: 'PRODUCT_PERSONALIZATION',
                personalizationLines,
            };
        }
        if (returnObject) {
            const personalization = null;
            const isMobileData = true;
            return {
                reqObj,
                history,
                partNumber,
                categoryId,
                categoryName,
                categoryPath,
                enableMiniCartView,
                handleMiniCartModalClick,
                personalization, // setting personalization to its default as we dont need it for mobile
                isMobileData, // setting isMobile true
                movieSelected,
                actionType,
            };
        }
        let passportBundle = {};
        if (isPassportBundleProduct?.enable) {
            passportBundle = {
                enable: true,
                passportSku: isPassportBundleProduct?.passportSku,
            };
        }

        if (wineModelOnAction) {
            closeAddonModal();
        }
        if (atcCallBack && typeof atcCallBack === 'function') {
            atcCallBack(); // callBack
        }
        actionAddToCart({
            item: [reqObj],
            history,
            categoryId,
            partNumber,
            categoryName,
            categoryPath,
            enableMinicart: enableMiniCartView,
            handleMiniCartModalClick,
            isMobile: actionType?.toLowerCase() !== 'quick view',
            eventType: actionType?.toLowerCase() !== 'quick view' ? 'Product Page' : actionType,
            passportBundle,
            movieSelected,
        });
        return true;
    };
    let conditionalClasses;
    if (fullWidth) {
        conditionalClasses = classes.addToCartButtonFullWidth;
    } else if (!disableAddToCart) {
        conditionalClasses = `${classes.addToCartButton} ${classes.addToCartBg}`;
    } else {
        conditionalClasses = classes.addToCartButton;
    }
    const getAvailabilityAddCart = () => {
        let availability = false;
        if (wineValidation) {
            const keys = Object.keys(wineValidation?.wineAvailability || {});
            keys.forEach((key) => {
                if (wineValidation?.wineAvailability[key].isProductAvailable && zipValid && wineAgeVerified) {
                    availability = true;
                }
            });
        }
        return availability;
    };

    const getPmallAddToCartEnabled = () => {
        let availability = false;
        const isPmallProduct = featureFlags['is-pmall-enabled'] && (brandId === '1030' || brandId === '1033');
        if (isPmallProduct && !pmallSummaryInfo?.customizations?.refnum  && selectedSku?.personalization?.personalizationAllowed) {
            availability = true;
        }
        return availability;
    };

    const isAddToCartDisabled = (
        disableAddToCart
        || (!isAddToCartEnabled && !getAvailabilityAddCart() && !isMobile)
        || getPmallAddToCartEnabled()
    );

    let node; let intersectValues;
    // custom hook for the intersection of two add to cart button and header
    if (typeof window !== 'undefined') {
        [node, intersectValues] = useIntersect({ rootMargin: '-10% 0px 0px 0px' });
    }

    // setting the postition to state
    useEffect(() => {
        if (intersectValues.intersectionRect?.top > intersectValues.boundingClientRect?.top) {
            setAddToCartPosition(() => true);
            actionSetAddToCartPosition('fixed');
        } else if (intersectValues.intersectionRect?.top === intersectValues.boundingClientRect?.top) {
            setAddToCartPosition(() => false);
            actionSetAddToCartPosition('static');
        }
    }, [intersectValues]);
    const hasPersonalization = featureFlags['is-personalization-pdp-enabled'] && selectedSku.personalization?.lines?.length > 0 && selectedSku.personalization?.indicator;
    // check for wineValidation is complete including zipCode
    const handleOpenPersonalizationModal = () => {
        setOpen(true);
    };
    // check for wineValidation is complete
    useEffect(() => {
        if (wineValidation?.ageValid && zipValid) {
            if (hasPersonalization) {
                handleOpenPersonalizationModal();
            }
        }
    }, [wineValidation?.ageValid, zipValid]);

    const style = {};
    if (fullWidth && !addToCartPosition) {
        style.width = '100%';
    }
    const disableLayout = (
        <div className={`${classes.buttonWrap} ${isPdpRedesignEnabled ? classes.newPdpAddToCart : ''}`}>
            <Button
                id="pwaAddToCartBtn"
                className={classes.disabledBtn}
                variant="contained"
                disabled
            >
                OUT OF STOCK
            </Button>
        </div>
    );
    const location = useLocation();
    const foodPlaRedesign = useSelector(getFeatureFlag('which-food-pla-design'));
    const isPlaPage = useIsPlaPage() && featureFlags['is-simplified-pdp'] && foodPlaRedesign === 'variant';
    const isShipNow = product?.availability?.deliveryMessage === 'AVAILABLE TO SHIP NOW';

    let layout = (
        <>
            {isAvailabilityAtcLeftAlign && actionType?.toLowerCase() !== 'quick view' && !featureFlags['is-pdp-qty-availability-enabled'] ? <div className={`${classes.shipmentMessage} ${isPdpRedesignEnabled ? classes.shipmentWrapper : ''} ${!isShipNow ? classes.shipmentMessageFuture : ''}`} availability="">{product?.availability?.deliveryMessage?.toLowerCase?.()}</div> : ''}
            <div className={`add-to-cart-container ${classes.buttonWrap} ${isPdpRedesignEnabled && actionType?.toLowerCase() !== 'quick view' ? classes.newPdpAddToCart : ''} ${leftAlignClass} ${!featureFlags['ATC_button_PDP'] ? classes.addToCartBtn : ''} ${!isPlaPage ? classes.addToCart : ''}`} id="contAddToCartBtn" ref={node}>
                <Button
                    style={style}
                    id="pwaAddToCartBtn"
                    disabled={isAddToCartDisabled}
                    onClick={() => {
                        if (((!getAvailabilityAddCart() && isMobile && hasWine) || (pdpInlineAddon && !getAvailabilityAddCart() && hasWine))) {
                            setOpenModal(true);
                            handleMobileAgeVerification(true, hasPersonalization ? {} : handleAddToCart(null, true));
                            return;
                        }
                        // Only open personalization modal for 18B
                        if (hasPersonalization) {
                        // if the item requires personalization then pause the execution of this function and open
                        // personalization modal
                            setOpen(true);
                            return;
                        }
                        handleAddToCart();
                    }}
                    className={`${conditionalClasses} ${addToCartPosition ? classes.addtoCartStickToHeader : ''} ${isPlaPage ? classes.plaAddtoCart : ''}`}
                    variant="contained"
                >
                    {isSubscriptionChecked ? 'Add Subscription to cart' : 'Add to cart'}
                </Button>
            </div>
            {!isPlaPage && actionType?.toLowerCase() !== 'quick view' && <div className={classes.klarnaWidget}><KlarnaCreditPromotionBadge selectedSku={selectedSku} /></div>}
            {isPlaPage && <a className={classes.fullDetail} href={location?.pathname}>See Full Details</a>}
        </>
    );
    if (productType === 'cyo') {
        const disabledCYOAddToCart = totalProductSelected === 0 || (totalProductSelected !== maxComponents) || !isBandItemSelected;
        layout = (
            <div className={classes.buttonWrap} id="contAddToCartBtn" ref={node}>
                <Button
                    id="pwaAddToCartBtn"
                    onClick={() => {
                        if (!getAvailabilityAddCart() && isMobile && hasWine) {
                            handleMobileAgeVerification(true, handleAddToCart(null, true));
                            return;
                        }
                        handleAddToCart();
                    }}
                    className={`${disabledCYOAddToCart ? classes.cyoAddToCartButton : `${classes.cyoAddToCartButton} ${classes.addToCartBg}` }
                    ${addToCartPosition ? classes.addtoCartStickToHeader : ''} ${isBYOProduct ? classes.byoAddToCart : ''}`}
                    variant="contained"
                    disabled={disabledCYOAddToCart && !isBYOProduct}
                >
                    {totalProductSelected !== 0 && totalProductSelected === maxComponents  && ((isBandItemSelected && !isBYOProduct) || isBYOProduct)
                        ? 'Add to Cart'
                        : `Select ${!isBandItemSelected ? 'Band' : `${maxComponents} Items`}`}
                </Button>
            </div>
        );
    }

    // submit data Personalizationmodel data When click on countinue
    const handlePersonalizationModalSubmit = () => {
        const newPersonalizationState = { ...personalizationData, modalOpen: false };
        setPersonalization({
            personalization: newPersonalizationState,
        });
        if (Object.keys(personalizationData).length > 0) {
            setOpen(false);
            setOpenModal(false);
            handleAddToCart();
        }
    };
    const compileSelectedItems = () => {
        const selectedMonthSku = product?.club?.defaultSku?.components.find((item) => item?.month?.toLowerCase() === selectedSku?.date?.month?.toLowerCase());
        const productArray = [];
        productArray.push({
            brandCode: selectedSku?.brandId,
            productCode: selectedMonthSku?.partNumber,
            quantity: selectedSku?.quantity || 1,
            componentType: selectedSku?.productType,
        });
        return productArray;
    };

    const addonsSku = selectAddons?.find((addon) => addon.ageVerifyFlag === true || addon.ageVerifyFlag === 'true');
    return (
        <>
            {selectedSku.personalization?.lines
            && (
                <PersonalizationModal
                    isOpen={open}
                    personalization={selectedSku.personalization}
                    setPersonalizationState={setPersonalizationState}
                    personalizationState={personalizationData.personalization}
                    handlePersonalizationTextFieldChange={handlePersonalizationTextFieldChange}
                    handlePersonalizationModalSubmit={handlePersonalizationModalSubmit}
                    closePersonalizationModal={closePersonalizationModal}
                    content={content}
                />
            ) }
            {openModal && !quickView && (
                <AddonVerification
                    selectedSku={(Object.keys(addonsSku || {})?.length > 0 ? addonsSku : selectedSku) || {}}
                    productSkus={product?.productSkus}
                    isAddonAvailable={Object.keys(addonsSku || {})?.length > 0}
                    featureFlags={featureFlags}
                    classes={classes}
                    pdpInlineAddon={pdpInlineAddon || wineModelOnAction}
                    handleMobileAgeVerification={handleMobileAgeVerification}
                    open={openModal}
                    setOpen={setOpenModal}
                    handleAddToCart={handleAddToCart}
                    productBrand={product?.brand || null}
                    components={selectedSku?.productType === 'CLUB' ? compileSelectedItems() : []}
                    defaultSku={selectedSku?.productType === 'CLUB' ? product?.club?.defaultSku?.partNumber : selectedSku?.id}
                />
            )}
            {productUnAvailable ? disableLayout : layout}
            {addToCartError && <AddToCartErorMessage error={addToCartError} />}
            <MaxCartErrorMessage />
        </>
    );
};

MobileFoodAddToCart.propTypes = {
    classes: object.isRequired,
    history: object.isRequired,
    categoryId: string.isRequired,
    partNumber: string.isRequired,
    selectedItemId: string,
    brandId: string.isRequired,
    actionAddToCart: func.isRequired,
    subscriptionDetails: shape({
        subscriptionType: string,
        subscriptionDuration: string,
        subscriptionFrequency: string,
        subscriptionDurationKey: string,
    }),
    subscription: shape({
        subscriptionKey: array,
        subscriptionIndefinite: string,
        subscriptionType: array,
        implementProductSubscription: bool,
        viewSubscription: bool,
        isTypeDiscovery: bool,
        subscriptionCheckbox: bool,
    }),
    productType: string,
    totalProductSelected: number,
    maxComponents: number,
    fullWidth: bool,
    cyoSelectedComponents: array,
    disableAddToCart: bool,
    personalizedData: shape({
        personalizationType: string,
        personalizationLines: arrayOf({
            perAttributeValue: string,
        }),
    }),
    isPersonalizable: bool,
    wineValidation: shape({
        wineAvailability: object, // object type array
        wineVerify: shape({
            birthDay: 'string',
            birthMonth: 'string',
            birthYear: 'string',
            minAge: 'string',
        }),
    }),
    isAddToCartEnabled: bool.isRequired,
    categoryPath: string,
    categoryName: string,
    actionSetAddToCartPosition: func.isRequired,
    isBandItemSelected: bool.isRequired,
    handleMobileAgeVerification: func,
    isMobile: bool,
    hasWine: bool,
    addons: arrayOf(shape({
        brandCode: string,
        productCode: number,
        componentType: string,
        productId: string,
        quantity: number,
    })),
    quantity: string,
    isPassportBundleProduct: shape({
        prices: {
            value: number,
        },
        enable: bool,
    }),
    contactId: string,
    movieSelected: shape({
        error: bool,
        skuSelected: shape({
            sku: string.isRequired,
            sku_description: string.isRequired,
            sku_price: number.isRequired,
        }),
    }),
    productDemandType: string,
    deliveryMethod: string.isRequired,
    addToCartError: string,
    isTryMeProduct: bool,
    deliveryIndicatorPDP: string.isRequired,
    pmallSummaryInfo: shape({
        customizations: arrayOf(
            shape({
                refnum: string,
                description: string,
                'preview-url': string,
            }),
        ),
    }).isRequired,
    handleMiniCartModalClick: func,
    enableMinicart: bool,
    actionType: string,
    subscriptionValues: shape({
        duration: string,
        subscriptionType: string,
    }),
    selectedSku: shape({
        id: string,
    }),
    open: bool.isRequired,
    setOpen: func.isRequired,
    closePersonalizationModal: func.isRequired,
    setPersonalizationState: func.isRequired,
    personalizationData: shape({
        personalization: shape({
            indicator: string,
            modalOpen: bool,
            lineState: arrayOf(
                shape({
                    lineText: string,
                    maxChars: number,
                }),
            ),
        }),
    }),
    setPersonalization: func.isRequired,
    handlePersonalizationTextFieldChange: func.isRequired,
    zipValid: bool,
    wineAgeVerified: bool.isRequired,
    selectAddons: array.isRequired,
    product: array.isRequired,
    pdpInlineAddon: bool,
    quickView: bool,
    closeAddonModal: func,
    isBYOProduct: bool,
    atcCallBack: func,
    content: object,
    isPdpRedesignEnabled: bool,
};

MobileFoodAddToCart.defaultProps = {
    selectedItemId: null,
    productType: '',
    totalProductSelected: 0,
    maxComponents: null,
    fullWidth: false,
    cyoSelectedComponents: [],
    subscriptionDetails: {},
    subscription: {},
    personalizationData: {},
    disableAddToCart: false,
    personalizedData: {},
    isPersonalizable: false,
    categoryPath: '',
    categoryName: '',
    wineValidation: null,
    handleMobileAgeVerification: () => { },
    isMobile: false,
    quantity: '1',
    hasWine: false,
    addons: [],
    isPassportBundleProduct: {},
    contactId: '',
    movieSelected: null,
    productDemandType: null,
    isTryMeProduct: false,
    addToCartError: '',
    handleMiniCartModalClick: () => { },
    enableMinicart: false,
    actionType: '',
    subscriptionValues: {},
    zipValid: null,
    selectedSku: {},
    pdpInlineAddon: false,
    quickView: false,
    closeAddonModal: () => { },
    isBYOProduct: false,
    atcCallBack: () => {},
    content: {},
    isPdpRedesignEnabled: false,
};

const mapStateToProps = (state) => ({
    zipValid: getIsProductFilterZipcodeValid(state),
    wineValidation: getWineData(state),
    wineAgeVerified: getWineAgeVerified(state),
    contactId: getContactId(state),
    addToCartError: getAddToCartError(state),
    pmallSummaryInfo: getPmallSummaryInfo(state),
    subscriptionValues: getSubscriptionFrequencyData(state),
});

const mapDispatchToProps = (dispatch) => ({
    actionAddToCart: bindActionCreators(addToCartNewCartServices, dispatch),
    actionSetAddToCartPosition: bindActionCreators(setAddtoCartPosition, dispatch),
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MobileFoodAddToCart)),
);
